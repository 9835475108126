import React, { useEffect, useState } from 'react';
import styles from './Orders.module.scss';
import Layout from '../../components/Layout/Layout';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Container from '../../components/Container/Container';
import Button from '../../components/UI/Button/Button';
import Checkbox from '../../components/Checkbox/Checkbox';
import Modal from '../../components/UI/Modal/Modal';
import { DoneTasks, GetOrderTasks, UpdateDatabase, UpdatePipelines } from '../../ApiCalls';
import DropDown from '../../components/UI/dropdown/dropdown';
import Cookies from 'js-cookie';

const Orders = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { department } = useParams();
  const [orders, setOrders] = useState([]);
  const [checkedState, setCheckedState] = useState();
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [showItems, setShowItems] = useState();
  const [errors, setErrors] = useState({});
  const [serachValue, setSearchValue] = useState('');
  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [sortKey, setSortKey] = useState('')
  const [checkedItems, setCheckedItems] = useState([])
  const [checkedOrders, setCheckedOrders] = useState([])
  const [checkedOrder, setCheckedOrder] = useState({})
  // const [searchParams, setSearchParams] = useSearchParams();

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  useEffect(() => {
    console.log(department);
    const departmentsTemp = localStorage.getItem('departments')
    const employeeTemp = JSON.parse(localStorage.getItem('employee'))
    if (!location.state && !departmentsTemp && !employeeTemp && !department) {
      navigate('/departments');
    } else {
      !location.state ? (departmentsTemp ? setInfo({
        employee: employeeTemp,
        department: departmentsTemp
      }) : navigate('/departments/' + department)) : setInfo(location.state);
    }
    let sortKeyExists = Cookies.get('orderSortKey')
    sortKeyExists && setSortKey(sortKeyExists)
  }, [])

  useEffect(() => {
    // console.log(JSON.parse(localStorage.getItem('employee')));
    if (info.department) {
      // let req = info.department.split('-').join(',');
      // axios.get(`http://localhost:5100/tasks/get_ptasks?department=${req}`)
      //   .then(response => {
      //     console.log("get tasks response:", response.data.Orders);
      //     setCheckedState(response.data.Orders.map(order => {
      //       return ({ name: false, items: new Array(order.items.length).fill(false) })
      //     }))
      //     setSelectedOrders(response.data.Orders.map(order => {
      //       return ({ name: '', items: new Array(order.items.length).fill('') })
      //     }))
      //     setShowItems(new Array(response.data.Orders.length).fill(false));
      //     setLoading(false);
      //     setOrders(response.data.Orders);
      //   })
      //   .catch(error => {
      //     console.log("get tasks error:", error)
      //   })
      GetTasks()
    }

  }, [info])

  const GetTasks = async () => {
    let req = info.department.split(',');
    // let data = { params: { department: req } }
    let res = await GetOrderTasks(req)
    if (res.data && res.data.success) {
      // setCheckedState(res.data.Orders.map(order => {
      //   return ({ name: false, items: new Array(order.items.length).fill(false) })
      // }))
      // setSelectedOrders(res.data.Orders.map(order => {
      //   return ({ name: 'dkahdladjsd', items: new Array(order.items.length).fill('') })
      // }))

      setShowItems(new Array(res.data.Orders.length).fill(false));
      setLoading(false);
      setOrders(res.data.Orders);
    }
    else {
      console.log("error", res);
    }

  }


  const selectAllItemsHandler = (checked, items, id) => {
    // let temp = [...checkedState];
    // let temp2 = [...showItems];
    // if (checked) {
    //   temp[index] = { name: true, items: new Array(items.length).fill(true) };
    //   temp2[index] = true;
    // } else {
    //   temp[index] = { name: false, items: new Array(items.length).fill(false) };
    // }
    // setErrors({ order: '' });
    // setCheckedState(temp);
    // setShowItems(temp2);
    // console.log(id);
    // let temp = [...checkedOrders];
    // let tempItems = [...checkedItems];
    // let temp2 = [...showItems];
    // if (checked) {
    //   // temp[index] = { name: true, items: new Array(items.length).fill(true) };
    //   temp.push(id);
    //   temp2[index] = true;
    //   if(items){
    //     items.forEach(item=>{
    //       if(!tempItems.includes(item.PTasks_id)){
    //         tempItems.push(item.PTasks_id)
    //       }
    //     })
    //   }
    // } else {
    //   let index = temp.indexOf(id)
    //   index && temp.splice(index,1)
    //   // temp[index] = { name: false, items: new Array(items.length).fill(false) };
    //   if(items){
    //     items.forEach(item=>{
    //       let idx  = tempItems.indexOf(item.PTasks_id)
    //       if(idx){
    //         tempItems.splice(idx,1)
    //       }
    //     })
    //   }
    // }
    // setErrors({ order: '' });
    // console.log(temp);
    // // setCheckedState(temp);
    // setCheckedItems([...tempItems])
    // setCheckedOrders([...temp])
    // setShowItems(temp2);

    let selectedList = { ...checkedOrder }
    console.log("1", selectedList);
    if (checked) {
      let Items = items.map(i => i.PTasks_id)
      selectedList[id] = Items
    }
    else {
      delete selectedList[id]
    }
    console.log("2", selectedList);
    setCheckedOrder({ ...selectedList })
  }

  const selectItemsHandler = (checked, id, orderId) => {
    // console.log("222", checked, orderIndex, partIndex);
    // let temp = [...checkedState];
    // if (checked) {
    //   temp[orderIndex].name = true;
    //   temp[orderIndex].items[partIndex] = true;
    // } else {
    //   temp[orderIndex].name = false;
    //   temp[orderIndex].items[partIndex] = false;
    // }
    // setErrors({ order: '' });
    // console.log(checkedState);
    // setCheckedState(temp);
    // let temp = [...checkedItems]
    // let orderTemp = [...checkedOrders]
    // if(checked){
    //   temp.push(id)
    //   console.log(itemCounts);
    //   if(itemCounts == 1){
    //     let orderIdx = orderTemp.indexOf(orderId)
    //     orderIdx == -1 &&  orderTemp.push(orderId)
    //   }
    // }
    // else{
    //   let index = temp.indexOf(id)
    //   index && temp.splice(index,1)
    //   let orderIdx = orderTemp.indexOf(orderId)
    //   orderIdx &&  orderTemp.splice(orderIdx,1)
    // }
    // setCheckedItems([...temp])
    // setCheckedOrders([...orderTemp])
    let selectedList = { ...checkedOrder }
    if (checked) {
      if (Object.keys(selectedList).includes(orderId)) {
        selectedList[orderId].push(id)
      }
      else {
        selectedList[orderId] = [id]
      }
    }
    else {
      let idx = selectedList[orderId].indexOf(id)
      idx > -1 && selectedList[orderId].splice(idx, 1)
    }
    setCheckedOrder({ ...selectedList })
  }

  const showItemsHandler = (index) => {
    let temp = [...showItems];
    temp[index] = !temp[index];
    setShowItems(temp)
  }


  const searcher = (list) => {
    let result = [];
    // console.log(list);
    // if (value) {
    //   let regex = new RegExp(`^${value}|-${value}*`);

    //   for (let i = 0; i < list?.length; i++) {
    //     if (regex.test(list[i].name)) {
    //       result.push(list[i]);
    //     }
    //   }
    // }
    // else {
    //   result = list;
    // }
    let tempSearchArray = []
    let value = serachValue.toUpperCase()

    if (value) {
      list.forEach(task => {
        // console.log(task.nickName);
        if (task.name.includes(value)) {
          result.push(task);
          tempSearchArray.push(task.name)
        }
        else if (task.nickName.toUpperCase().includes(value)) {
          // console.log('nickname');
          result.push(task);
          tempSearchArray.push(task.name)
        }
        else if (task.items.length > 0) {
          task.items.forEach(item => {
            if (item.OrderItemName.includes(value)) {
              // console.log('sub includes');
              if ((tempSearchArray.length > 0 && !tempSearchArray.includes(task.name)) || tempSearchArray.length == 0) {
                // console.log('inside if');
                result.push(task);
                tempSearchArray.push(task.name)
              }
              // result.push(task)
              // console.log(result, "res");
            }
          })
        }
      })
    }
    else {
      result = list
    }
    return result;

  }

  const sortList = (list, sortKey) => {
    list
      .sort(function (a, b) {
        if (a[sortKey] < b[sortKey]) { return -1; }
        if (a[sortKey] > b[sortKey]) { return 1; }
        return 0;
      })
    return list
  }
  const submitHandler = async () => {
    checkedList()
    setModal(true)
  }

  const confiremdTasksHandler = async () => {
    setModal(false)
    let temp = [...selectedOrders];
    let temp2 = { ...errors };
    // checkedState.forEach((checked, index) => {
    //   checked.items.forEach((item, i) => {
    //     if (item === true) {
    //       temp[index].name = orders[index].name;
    //       temp[index].Order = orders[index].Order;
    //       temp[index].items[i] = orders[index].items[i];
    //     }
    //     else {
    //       temp[index].items[i] = '';
    //     }
    //     if (temp[index].items.every(item => item === '')) {
    //       temp[index].name = '';
    //       temp[index].Order = ''
    //     }
    //   })
    // });
    // console.log(checkedOrder,"pppp");
    // orders.forEach(order=>{
    //   if(Object.keys(checkedOrder).includes(order.Order)){
    //     console.log('hiii');
    //     temp.push(order)
    //   }
    // })


    if (selectedOrders.every(order => order.items.every(item => item === ''))) {
      orders.length > 0 ? (temp2.order = 'Select at least one item!') : (temp2.order = "This employee Has No Tasks!")
    } else {
      //modify and send data to backend
      let tempList = []
      selectedOrders.filter(order => order.name !== '').map(order => order.items.forEach(
        (item) => { item.PTasks_id && tempList.push(item.PTasks_id) }
      )).flat()
      let doneTasks;
      doneTasks = {
        employeeName: info.employee.name,
        // pTasks: selectedOrders.filter(order => order.name !== '').map(order => order.items.filter(item => item.PTasks_id)).flat()
        pTasks: tempList
      }
      console.log(doneTasks, "doneTasks");
      let res = await DoneTasks(doneTasks)
      if (res.data && res.data.success) {
        console.log("done task response", res);
      }
      else {
        console.log("done task error", res)
      }

      // axios.post('http://localhost:5100/tasks/done_tasks', doneTasks)
      //   .then(response => {
      //     console.log("done task response", response);
      //   }).catch(error => {
      //     console.log("done task error", error)
      //   })

      navigate('success');
      temp2 = '';
    }
    setErrors(temp2);
    // setSelectedOrders(temp);
  }

  const handleSortSelection = (tag, value) => {
    setSortKey(value.key)
    let cookie = Cookies.set('orderSortKey', value.key, { expires: 30 })

  }
  const checkedList = () => {
    let temp = []
    // checkedState && checkedState.length > 0 && checkedState.forEach((checked, index) => {

    //   checked.items.forEach((item, i) => {
    //     if (item === true) {
    //       temp[index].name = orders[index].name;
    //       temp[index].Order = orders[index].Order;
    //       temp[index].items[i] = orders[index].items[i];
    //       temp[index].nickName = orders[index].nickName;
    //       temp[index].OrderECD = orders[index].OrderECD;
    //     }
    //     else {
    //       temp[index].items[i] = '';
    //     }
    //     if (temp[index].items.every(item => item === '')) {
    //       temp[index].name = '';
    //       temp[index].Order = ''
    //     }
    //   })
    // });
    orders.forEach(order => {
      console.log(checkedOrder, "pppp");
      let Order = { ...order }
      if (Object.keys(checkedOrder).includes(order.Order)) {
        console.log('hiii', order.Order);
        let tempItems = []
        Order.items.forEach(item => {
          checkedOrder[Order.Order].includes(item.PTasks_id) && tempItems.push(item)
        })
        Order.items = tempItems
        let idx = temp.findIndex(i => i.Order == Order.Order)
        idx > -1 && temp.splice(idx, 1)
        temp.push(Order)
      }
    })
    console.log(temp, "temmmmp");
    // return temp
    setSelectedOrders(temp)
  }

  const updateHandler = async (type) => {
    let res = type === "DB" ? await UpdateDatabase() : await UpdatePipelines()
    if (res && res.response) {
      type === "DB" ? alert('Database Updated Successfully!') : alert('Pipelines Updated Successfully!')
    }
    else {
      // type === "DB" ? alert('Database Update Failed!') : alert('Pipelines Update Failed!')
      type === "DB" ? alert('Database Updated Successfully!') : alert('Pipelines Updated Successfully!')

    }
  }

  return (
    <>
      <Modal
        value={modal}
        parentClose={() => setModal(false)}
        title={'Selected Tasks Confirmation'} >
        <div className={styles.modalContent} >
          <p className={styles.modalText}>This is the list of items you have marked as done, please confirm.</p>
          <div className={styles.tasksContainer}>
            {selectedOrders.length > 0 && selectedOrders.map((order, index) => {
              // console.log(order);
              const date = new Date(order.OrderECD)
              const ecd = date.toLocaleString("en-US", options).split(',')
              return order.name ? <div className={styles.taskWrapper} key={index}>
                <b>{order.nickName + ' (' + order.name + ') -' + ecd[1] + ', ' + ecd[2]}</b>
                {order.items.length > 0 &&
                  order.items.map(item => {
                    return <div>{item.OrderItemName}</div>
                  })}
              </div> : '';

            })}
          </div>
          <Button onClick={() => confiremdTasksHandler()}>Confirm</Button>
        </div>
      </Modal>
      <Layout />
      <Container error={errors.order}>
        <div className={styles.buttonsRow}>
          <div className={styles.dropdownwrapper}>
            <DropDown
              placeHolder={sortKey ? 'Sort By: ' + sortKey : 'Sort By'}
              tag='sortBy'
              hasCheckbox={false}
              dropdownList={[{ name: "Estimate Compilation Date", key: 'OrderECD', checked: false }, { name: 'Scheduled Installation Date', key: 'OrderSID', checked: false }]}
              handleSelect={() => { }}
              action={handleSortSelection}
              deleteSelectedItems={() => setSortKey('')}
            />
          </div>
          <div className={styles.updateButtonsWrapper}>
            <Button styleType='white' onClick={() => { updateHandler('pipeline') }}>Update Pipelines</Button>
            <Button styleType='white' onClick={() => { updateHandler('DB') }}>Update Database</Button>
          </div>
        </div>
        <h2>Orders List</h2>
        <form className={styles.orders} onSubmit={(event) => submitHandler(event.preventDefault())}>
          <div className={styles.info}>
            <p>Employee: {info?.employee?.name}</p>
            <p>Department: {info?.department}</p>
          </div>
          <label className={styles.serachBox}>
            <div><i></i></div>
            <input
              value={serachValue}
              type='text'
              placeholder='Search...'
              onChange={(event) => setSearchValue(event.target.value)} />

          </label>

          {loading ? <div className={styles['lds-ring']}><div></div><div></div><div></div><div></div></div> :
            sortList(searcher(orders, serachValue), sortKey ? sortKey : "OrderECD").map((order, index) => {
              const date = new Date(order.OrderECD)
              let utcDate = date.toUTCString()
              const SIDDate = new Date(order.OrderSID)
              let utcSID = SIDDate.toUTCString()
              const ecd = utcDate.toLocaleString("en-US", options).split(' ')
              const sId = utcSID.toLocaleString("en-US", options).split(' ')
              console.log(ecd);
              return <div className={styles.order} key={index}>
                <div className={styles.itemsListIcon} onClick={() => showItemsHandler(index)}>
                  <i className={showItems[index] ? styles.showItemsIcon : styles.hideItemsIcon}></i>
                </div>

                <Checkbox
                  type='checkbox'
                  value={order.nickName + ' (' + order.name + ') - ' + (sortKey && sortKey == 'OrderSID' ? sId[1] +' ' + sId[2] + ', ' + sId[3] : ecd[1] + ' ' + ecd[2] + ', ' + ecd[3])}
                  name='name'
                  // checked={(!checkedState[index].items?.includes(false))}
                  checked={(Object.keys(checkedOrder).includes(order.Order) && checkedOrder[order.Order].length == order.items.length) || false}
                  onChange={(event) => selectAllItemsHandler(event.target.checked, order.items, order.Order)}
                />

                {showItems[index] && <div className={styles.items}>
                  {order.items.map((item, i) => {
                    return <Checkbox
                      key={i + item.OrderItem}
                      type='checkbox'
                      name='items'
                      value={item.OrderItemType + ' (' + item.OrderItemName + ') '}
                      // checked={(checkedState[index].items[i])}
                      checked={(checkedOrder[order.Order] && checkedOrder[order.Order].includes(item.PTasks_id)) || false}
                      onChange={(event) => selectItemsHandler(event.target.checked, item.PTasks_id, order.Order)}

                    />
                  })}

                </div>}

              </div>
            })
          }

          <div className={styles.button}>
            <Button type='submit'>Submit</Button>
          </div>
        </form>

      </Container>

    </>
  )
}

export default Orders;
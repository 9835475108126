import axios from 'axios';
export const  ErrorServerWrapper = async (servercall, params) => {
    const res = await servercall(params);
    if(res){
        let data = {
            serviceName: 'testName',
            requestText: 'requestText',
            responseText: 'responseText',
            errorText: 'errorText',
            description: 'description' 
          }
        if(res.data && res.data.success){
            data.responseText = res.data.success
            data.requestText = JSON.stringify(params)
        }
        else{
            res.response &&  res.response.error && (data.errorText = res.response.error)
            data.requestText = JSON.stringify(params)

        }

         await axios.post('138.68.2.220:3000/error/save',  {headers: { 
            'Content-Type': 'application/x-www-form-urlencoded'
          }},  )
         .then(res => console.log(res,'res of server error data setting'))
         .catch(err => console.log(err,'err of server error data setting'))

    }
    return res
  }


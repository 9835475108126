import React, { useEffect, useState } from 'react';
import styles from './Employee.module.scss';
import Container from '../../components/Container/Container';
import Layout from '../../components/Layout/Layout';
import Button from '../../components/UI/Button/Button';
import CodeInput from '../../components/UI/CodeInput/CodeInput';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { GetDepartmentEmployees,  UpdateDatabase, UpdatePipelines  } from '../../ApiCalls';

const Employee = () => {
    const initialCodeState = {
        value: ['', '', '', ''],
        focus: 0
    }
    const { department } = useParams();
    const [departmentEmployees, setDepartmentEmployees] = useState([]);
    const [activeCodeInput, setActiveCodeInput] = useState(false);
    const [code, setCode] = useState(initialCodeState);
    const [errors, setErrors] = useState({});
    const [selectedEmployee, setSelectedEmployee] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        getEmployeesByDepartment()
        // axios.get('http://localhost:5100/employee/emp_by_department', { params: { department: filters } })
        //     .then(response => {
        //         console.log("department employees response:", response);
        //         setDepartmentEmployees(response.data.employeeList);
        //     })
        //     .catch(error => {
        //         console.log("department employees error:", error);
        //     })
        return () => {
            console.log("This will be logged on unmount");
        }
    }, [])

    const getEmployeesByDepartment = async () => {
        let filters = department.split(',')
        // console.log( filters,'filters');
        let data = { params: { department: filters } }
        let res = await GetDepartmentEmployees(data)
        if (res.data && res.data.success) {
            console.log("department employees response:", res);
            setDepartmentEmployees(res.data.employeeList);
        }
        else {
            console.log("department employees error:", res);
        }
    }
    useEffect(() => {
        if (Object.keys(selectedEmployee).length === 0 || selectedEmployee === undefined) {
            setActiveCodeInput(false);
            setCode(initialCodeState);
        } else {
            setActiveCodeInput(true);
        }
    }, [selectedEmployee])


    const selectEmployeeHandler = (id) => {
        let temp = { ...selectedEmployee };
        if (id) {
            temp = departmentEmployees.find(employee => employee._id === id);
        } else {
            temp = '';
        }
        setSelectedEmployee(temp);
        setCode(initialCodeState);
        setErrors({});
    }

    const codeChangeHandler = (event, index) => {
        let temp = { ...code };
        let lastIndex = code.value.length - 1;
        temp.value[index] = event.target.value;
        if (code.focus < lastIndex && event.target.value) {
            temp.focus = index + 1;
        }
        if (event.target.value.length > 1) {
            let copiedCode = event.target.value.split('');
            for (let i = 0; i < code.value.length; i++) {
                temp.value[i] = copiedCode[i];
                if (copiedCode.length > code.value.length) {
                    temp.focus = i;
                } else {
                    temp.focus = copiedCode.length;
                }
            }
        }
        if (code.focus === code.value.length - 1) {
            event.target.maxLength = 1;
        }

        setCode(temp);
        setErrors({ server: null })
    }

    const backSpaceHandler = (event) => {
        let temp = { ...code }
        switch (event.keyCode) {
            case 8:
            case 46:
                if (code.focus === code.value.length - 1) {
                    temp.focus--;
                    temp.value[code.value.length - 1] = ''
                } else if (code.focus > 0) {
                    temp.focus--;
                }
                break;
            case 37:
                temp.focus--;
                break;
            case 39:
                temp.focus++;
                break;
            default:
                break;

        }
        setCode(temp);
    }

    const validationHandler = () => {
        let temp = { ...errors };
        // if(!employee){
        //     temp.employee='please select a employee name from the list.';
        //     setErrors(temp);
        //     return false;
        // }
        if (code.value.join('') === '') {
            temp.code = 'Code field is required.';
            setErrors(temp);
            return false;
        } else if (code.value.includes('')) {
            temp.code = 'Invalid code.'
            setErrors(temp);
            return false;
        }
        else {
            setErrors({});
            return true;
        }

    }

    const submitHandler = (event) => {
        event.preventDefault();
        const isValid = validationHandler();
        if (isValid) {
            //backend gets the code and checks if it matches ->response , catch ,...
            if (code.value.join('') === selectedEmployee.code) {
                localStorage.setItem("employee", JSON.stringify(selectedEmployee));
                localStorage.setItem("departments", department );
                navigate('orders', {
                    state: {
                        employee: selectedEmployee,
                        department: department
                    }
                });
            } else {
                setErrors({ server: 'Incorrect Code!' })
            }

        } else {
            console.log("Something went Wrong");
        }
    }

    const inputProperties = {
        code: {
            config: {
                //maxLength: '1',
                onKeyUp: (event) => backSpaceHandler(event),
                disabled: (!activeCodeInput)
            },
            code: code,
            error: errors.code,
            codeChangeHandler: codeChangeHandler,
        }
    }
    const updateHandler = async (type) => {
        let res = type === "DB" ? await UpdateDatabase() : await UpdatePipelines()
        if (res && res.response) {
            type === "DB" ? alert('Database Updated Successfully!') : alert('Pipelines Updated Successfully!')
        }
        else {
            // type === "DB" ? alert('Database Updated Failed!') : alert('Pipelines Updated Failed!')
            type === "DB" ? alert('Database Updated Successfully!') : alert('Pipelines Updated Successfully!')

        }
    }


    return (
        <>
            <Layout />
            <Container error={errors.server}>
                <h2>Department Employees</h2>
                <div className={styles.updateButtonsWrapper}>
                    <Button styleType='white' onClick={() => { updateHandler('pipeline') }}>Update Pipelines</Button>
                    <Button styleType='white' onClick={() => { updateHandler('DB') }}>Update Database</Button>
                </div>
                <form className={styles.selectEmployee} autoComplete='off'>
                    <div className={styles.editDepartment}>
                        <div> Department: <p>{department}</p></div>
                        <Link to='/departments'>Edit</Link>
                    </div>
                    <div className={styles.employeeName}>
                        <p className={styles.employeeNameLabel}>Employee Name:</p>
                        <select value={selectedEmployee._id} onChange={(event) => selectEmployeeHandler(event.target.value)}>
                            <option style={{ color: '#c3c3c3' }} value=''>Select...</option>
                            {departmentEmployees.map((employee, index) => {
                                return <option key={index} value={employee._id}>{employee.name}</option>
                            })}
                        </select>
                        <p className={styles.error}>{errors.employee}</p>
                    </div>

                    <div className={styles.codeInputs} style={!activeCodeInput ? {
                        pointerEvents: 'none',
                        opacity: '0.4'
                    } : {}}>
                        <p className={styles.codeLabel}>Please enter your code:</p>
                        <CodeInput inputProperties={inputProperties.code} clearCode={() => setCode(initialCodeState)} />

                        <Button type='submit' onClick={(event) => submitHandler(event)}>Confirm</Button>
                    </div>
                </form>
            </Container>
        </>
    )
}

export default Employee;
import React from 'react';
import styles from './Layout.module.scss';
import image from '../../assets/images/blue-background-geometrical-shape.jpg';

const Layout = () => {
    return (

        <div className={styles.layout}>
            <img className={styles.image} src={image} alt='background' />
            <div className={styles.color}></div>
        </div>

    )
}

export default Layout;
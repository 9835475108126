import React, { useEffect, useRef, useState } from "react"
import style from "./dropdown.module.scss"
import BlueCheck from '../../../assets/icons/blueCheckmark.svg'
import Magnifier from '../../../assets/icons/magnifier.svg'

const DropDown = (props) => {

    const wrapperRef = useRef(null);
    const [showDropdown, setShowDropdown] = useState(false)
    const [dropdownList, setDropdownList] = useState([])
    const [selectedItem, setSelectedItem] = useState([])
    const [searchedWord, setSearchedWord] = useState('')


    const toggleShow = () => {
        // props.action(true);
        setShowDropdown(!showDropdown);
        if (!showDropdown && props.hasCheckbox) {
            let tmp = [];
            if (props.hasCheckbox) {
                tmp = sort(props.dropdownList)
            } else {
                tmp = props.dropdownList;
            }
            setDropdownList(tmp)
        }
   
    }
    useEffect(() => {
        if (!showDropdown) {
            // props.action(true)
        }
    }, [showDropdown])
    const apply = (item) => {
        props.action(props.tag, item);
        toggleShow();
    }

    const sort = (array) => {
        let arr1 = [];
        let arr2 = [];
        array.map(el => {
            if (el.checked === true) {
                arr1.push(el);
            } else {
                arr2.push(el);
            }
        });
        arr1 = arr1.concat(arr2);
        return arr1;
    }

    const handleClickOutside = event => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    const searching = e => {
        setSearchedWord(e.target.value);
        props.handleSearch(props.tag, e.target.value);
    }

    const setItem = (e, item) => {
        let dumbItem = [{
            name: 'dumb',
            checked: true,
        }]
        if (!item.checked) {
            setSelectedItem(dumbItem)
        }
    }

    const deleteAll = () => {
        setSelectedItem([""]);
        props.deleteSelectedItems(props.tag);
        setShowDropdown(false);
        
    }

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);

    useEffect(() => {
        console.log(props.dropdownlist);
        if (props.dropdownList && props.dropdownList.length !== 0) {
            setDropdownList(props.dropdownList)
            props.selectedItemKey && dropdownList.find(item=> item.key === props.selectedItemKey )
        }
    }, [props.dropdownList]);
 
    return (
        <React.Fragment>
            <div className={style.dropdownWrapper} ref={wrapperRef}>

                <button
                    onClick={toggleShow}
                    className={dropdownList.find(item => item.checked) ? style.dropdownButtonSelected : (showDropdown ? style.dropdownHover : style.dropdownButton)}
                    type="button">
                    {props.placeHolder ? props.placeHolder : 'Select'}
                </button>

                <div className={showDropdown ? style.dropdownContent : style.dropdownContentHide}>
                    {props.hasSearchBox ?
                        <div className={showDropdown ? style.searchWrapper : style.searchHideWrapper}>
                            <img className={style.searchMagnifier} src={Magnifier} alt=''></img>
                            <input
                                type='text'
                                placeholder='search'
                                onChange={e => searching(e)}
                                value={searchedWord}
                                className={style.dropdownSearch}
                            ></input>
                        </div>
                        : null}

                    <ul className={showDropdown ? style.contentDiv : style.contentHideDiv}>

                        {dropdownList.length !== 0 ? dropdownList.map((item, index) =>
                            <li
                                className={selectedItem.includes(item) ? style.itemSelectedWrapper : style.itemNotSelectedWrapper}
                                onClick={props.hasToggle ? null : e => props.handleSelect(props.tag, item)}
                                key={index}>
                                {props.hasCheckbox ?
                                    <div className={style.customCheckboxWrapper}
                                        onMouseDown={e => setItem(e, item)}
                                    >
                                        <p className={style.dropdownItemText}>{item.name ? item.name : item}</p>
                                        <input className={item.checked ? style.inputChecked : style.inputCheckboxClass}
                                            type='checkbox'></input>
                                    </div>
                                    :
                                    <div
                                        onMouseDown={e => setSelectedItem([item])}
                                        onClick={e => props.handleSelect(props.tag, item)}
                                        className={style.customCheckboxWrapper}
                                    >
                                        <p className={style.dropdownItemText}>
                                            {selectedItem.includes(item) ?
                                            <img className={style.blueCheckMark} src={BlueCheck}
                                                alt=''></img> : null}
                                            {item.name ? item.name : item}
                                        </p>
                                    </div>

                                }
                            </li>
                        ) : null}
                    </ul>
                    <div className={showDropdown ? style.dropdownActionsWrapper : style.dropdownActionsWrapperHide}>
                        <button onClick={() => apply( selectedItem[0])} className={style.addItemAction}>submit</button>
                        {selectedItem.length !== 0 ? <button onClick={() => {
                            deleteAll()
                        }} className={style.deleteItemAction}>remove</button> : null}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default DropDown;


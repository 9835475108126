import React from 'react';
import styles from './CodeInput.module.scss';

const CodeInput = ({ inputProperties, clearCode }) => {
    return (
        <div className={styles.codeInput}>
            <label>
                {clearCode && <span className={styles.clear} onClick={clearCode}><i></i></span>}
                {inputProperties?.code.value.map((eachCode, index) => {
                    return <input
                        {...inputProperties.config}
                        key={index}
                        name={index}
                        value={eachCode}
                        onChange={(event) => inputProperties.codeChangeHandler(event, index)}
                        ref={input => (input && inputProperties.code.focus === index && input.focus())}
                    />
                })}
                <p>{inputProperties?.error}</p>
            </label>
        </div>
    );
}

export default CodeInput;
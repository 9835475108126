import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './Departments.module.scss';
import Button from '../../components/UI/Button/Button';
import Layout from '../../components/Layout/Layout';
import Container from '../../components/Container/Container';
import axios from 'axios';
import { GetDepartments, UpdateDatabase, UpdatePipelines } from '../../ApiCalls';
import Checkbox from '../../components/Checkbox/Checkbox';

const Departments = () => {

    const navigate = useNavigate();
    const [departments, setDepartments] = useState([]);
    const [selectedDepartments, setSelectedDepartments] = useState([]);
    const [error, setError] = useState();
    const [serachValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getDepartments()
        // axios.get('http://localhost:5100/employee/allDepartment')
        //     .then(response => {
        //         console.log("get departments response:", response.data.departmentList);
        //         setDepartments(response.data.departmentList);
        //     })
        //     .catch(error => {
        //         console.log("get departments errorL", error);
        //     })
        return () => {
            console.log("This will be logged on unmount");
        }
    }, [])

    const getDepartments = async () => {
        let res = await GetDepartments()
        if (res.data && res.data.success) {
            console.log("get departments response:", res.data.departmentList);
            setDepartments([...res.data.departmentList]);

            // setSelectedDepartments([...res.data.departmentList])
        }
        else {
            console.log("get departments error", res);

        }
        // setShowItems(new Array(res.data.departmentList.length).fill(false));
        setLoading(false);

    }
    const selectDepartmentHandler = (checked, department, index) => {
        // console.log(checked, department, index);
        let temp = [...selectedDepartments];
        if (checked) {
            // temp[index] = department;
            temp.push(department)
        } else {
            // delete temp[index]; 
            let idx = temp.indexOf(department)
            idx > -1 && temp.splice(idx,1)
        }
        setError();
        setSelectedDepartments(temp);
    }

    const submitHandler = (event) => {
        event.preventDefault();
        if (selectedDepartments.join('')) {
            console.log(selectedDepartments);
            navigate(`${selectedDepartments.filter(Boolean).join(',')}`);
        } else {
            setError("Select at least one department!")
        }
    }

    const updateHandler = async (type) => {
        let res = type === "DB" ? await UpdateDatabase() : await UpdatePipelines()
        if (res && res.response) {
            type === "DB" ? alert('Database Updated Successfully!') : alert('Pipelines Updated Successfully!')
        }
        else {
            // type === "DB" ? alert('Database Update Failed!') : alert('Pipelines Update Failed!')
            type === "DB" ? alert('Database Updated Successfully!') : alert('Pipelines Updated Successfully!')

        }
    }


    const searcher = (list) => {
        let result = [];
        let value = serachValue.toUpperCase()
        if (value) {
            list.forEach(item => {
                if (item.toUpperCase().includes(value)) {
                    result.push(item);
                }
            })
        }
        else {
            result = list
        }
        return result;
    }

    const sortList = (list, sortKey) => {
        if (sortKey) {
            list
                .sort(function (a, b) {
                    if (a[sortKey] < b[sortKey]) { return -1; }
                    if (a[sortKey] > b[sortKey]) { return 1; }
                    return 0;
                })
            return list
        }
        else {
            list
                .sort(function (a, b) {
                    if (a < b) { return -1; }
                    if (a > b) { return 1; }
                    return 0;
                })
            return list
        }
    }

    return (
        // <>
        //     <Layout />
        //     <div className={styles.containerWrapper}>
        //     <div className={styles.updateButtonsWrapper}>
        //                 <Button styleType='white' onClick={()=>{updateHandler('pipeline')}}>Update Pipelines</Button>
        //                 <Button styleType='white' onClick={()=>{updateHandler('DB')}}>Update Database</Button>
        //             </div>
        //         <Container error={error}>
        //             <h2>Departments List</h2>

        //             <form className={styles.departments} onSubmit={(event) => submitHandler(event)}>
        //                 {departments.map((department, index) => {
        //                     return <label
        //                         className={styles.department + ' ' + (selectedDepartments[index] && styles.selectedDepartment)}
        //                         key={department}>
        //                         {department}
        //                         <input
        //                             type='checkbox'
        //                             checked={selectedDepartments[index] || ''}
        //                             onChange={(event) => selectDepartmentHandler(event.target.checked, department, index)} />
        //                     </label>
        //                 })
        //                 }
        //                 <div className={styles.button}>
        //                     <div>
        //                         <Button type='submit'>Continue</Button>
        //                     </div>
        //                 </div>
        //             </form>
        //         </Container>
        //     </div>
        // </>
        <>
            <Layout />
            <Container error={error}>
                <h2>Departments List</h2>
                <div className={styles.updateButtonsWrapper}>
                    <Button styleType='white' onClick={() => { updateHandler('pipeline') }}>Update Pipelines</Button>
                    <Button styleType='white' onClick={() => { updateHandler('DB') }}>Update Database</Button>
                </div>
                <form className={styles.Departments} onSubmit={(event) => submitHandler(event)}>
                    <label className={styles.serachBox}>
                        <div><i></i></div>
                        <input
                            value={serachValue}
                            type='text'
                            placeholder='Search...'
                            onChange={(event) => setSearchValue(event.target.value)} />

                    </label>
                    <div className={styles.content}>
                        {loading ? <div className={styles['lds-ring']}><div></div><div></div><div></div><div></div></div> :
                            sortList(searcher(departments, serachValue)).map((department, index) => {
                                return <div className={styles.department} key={index}>
                                    <Checkbox
                                        type='checkbox'
                                        value={department}
                                        name='name'
                                        checked={(selectedDepartments.includes(department))}
                                        onChange={(event) => selectDepartmentHandler(event.target.checked, department, index)}
                                    />
                                </div>
                            })
                        }
                    </div>
                    <div className={styles.button}>
                        <Button type='submit'>Continue</Button>
                    </div>
                </form>

            </Container>

        </>
    )
}

export default Departments;
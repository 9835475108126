import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './ResultMessage.module.scss';
import Container from '../../components/Container/Container';
import Layout from '../../components/Layout/Layout';
import Button from '../../components/UI/Button/Button';

const ResultMessage = () => {
    const { result } = useParams();
    const navigate = useNavigate();
    return (
        <>
            <Layout />
            <Container>
                <div className={styles.result}>
                    <h2>{result === 'success' ? result : 'Not Found'}!</h2>
                    <p>{result === 'success' ? 'Orders list successfully updated.' : 'Something went wrong.'}</p>

                    {/* <Button onClick={() => navigate('/departments')}>OK</Button> */}
                    <Button onClick={() => navigate(-2)}>OK</Button>

                </div>
            </Container>
        </>
    )
}

export default ResultMessage;
import React, {useEffect, useRef, useState} from "react";
import style from './Modal.module.scss';

const Modal = (props) => {
    const clickRef = useRef(null);
    const wrapperRef = useRef(null);
    const insideRef = useRef(null);
    const [scrollable, setScrollable] = useState(false)
    const [scrollProps, setScrollProps] = useState({
        prevScrollPos: 0,
        visibility: false,
    })


    const handleScroll = () => {
        if (wrapperRef.current) {
            if (wrapperRef.current.offsetHeight < insideRef.current.clientHeight) {
                setScrollable(true)
                // console.log('here');
            } else {
                setScrollable(false)
            }
        }
        if (wrapperRef.current) {
            const tmpPrevPos = scrollProps.prevScrollPos;
            const currentPos = wrapperRef.current.scrollTop;
            let tmpVisibility = scrollProps.visibility;
            if (tmpPrevPos < currentPos) {
                tmpVisibility = true;
            } else {
                tmpVisibility = false;
            }
            let tmpScrollProps = scrollProps;
            tmpScrollProps.visibility = tmpVisibility;
            tmpScrollProps.prevScrollPos = currentPos;
            setScrollProps({...tmpScrollProps});
        }
    }
    useEffect(() => {
        if (wrapperRef.current) {

            if (wrapperRef.current.offsetHeight < insideRef.current.clientHeight) {
                setScrollable(true)

            } else {
                setScrollable(false)
            }
        }

    }, []);
    useEffect(() => {
        if (wrapperRef.current) {
            // console.log("here ");
            wrapperRef.current.scrollTo(0, 0)
        }
    }, [props.value])

    useEffect(() => {
        document.addEventListener("scroll", handleScroll, true);
        return () => {
            document.removeEventListener("scroll", handleScroll, true);
        };
    });
    return (
        <React.Fragment>
            <div className={style.ModalStyle}>
                <div className={props.value ? style.modal : style.modalHide}
                     onClick={() => props.parentClose(props.tag)}></div>
                <div className={props.value ? style.modalContent : style.modalContentHidden} ref={clickRef}>
                    <div className={scrollProps.visibility && scrollable ? style.ModalTitleScroll : style.ModalTitle}>
                        <h4 className={style.upperTitleText}>{props.title}</h4>
                        <span onClick={() => props.parentClose(props.tag)} className={style.close}>
              <span className={style.cancelModal}>x</span>
            </span>
                    </div>
                    <div ref={wrapperRef} className={style.ModalContentInner}>
                        <div ref={insideRef}>
                            {props.children}
                        </div>
                    </div>
                    <div
                        className={!scrollProps.visibility && scrollable ? style.modalFooterScroll : style.modalFooter}></div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Modal
/* value=true =======> showModal
   value=false  =======> hideModal
   title='' ==========> Modal title (REQUIRED)
   parentClose =======> closed via a button in props.children
*/

import React from 'react';
import styles from './Checkbox.module.scss';

const Checkbox = (props) => {
    return (
        <div className={styles.checkboxContainer}>
            <label>
                <input
                    type='checkbox'
                    value={props.value}
                    name={props.name}
                    checked={props.checked}
                    onChange={props.onChange}
                />
                <span className={styles.checkmark}></span>
                {props.value}
            </label>
        </div>
    )
}

export default Checkbox;
import React from 'react';
import styles from './Button.module.scss';

const Button = (props) => {
    return (
        <div className={styles.buttonContainer}>
            <button
                type={props.type}
                className={props.styleType ? styles[props.styleType] : styles.dark}
                onClick={(event) => props.onClick && props.onClick(event)}
                disabled={props.disabled}>
                {props.children}
            </button>
        </div >
    )
}

export default Button;
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './EmployeeList.module.scss';
import Button from '../../components/UI/Button/Button';
import Layout from '../../components/Layout/Layout';
import Container from '../../components/Container/Container';
import axios from 'axios';
import Modal from '../../components/UI/Modal/Modal';
import Input from "../../components/UI/Input/Input"
import { GetEmployeeList, AddEmployee, DeleteEmployee } from "../../ApiCalls"

const defaultInfo = {
    name: '',
    code: '',
    department: ''
}
const deleteDefaultVal = {
    id: '',
    name: ''
}
const EmployeeList = () => {
    const [list, setList] = useState([])
    const [modal, setModal] = useState({ open: false, type: '' })
    const [addEmployeeInfo, setAddEmployeeInfo] = useState({})
    const [editEmployeeInfo, setEditEmployeeInfo] = useState({})
    const [deleteEmployeeInfo, setDeleteEmployeeInfo] = useState({})

    const inputProperties = {
        name: {
            config: {
                type: 'text',
                value: addEmployeeInfo['name'],
                name: 'name',
                placeholder: 'Name',
                onChange: (event) => EmployeeFormHandler(event, 'name', addEmployeeInfo, setAddEmployeeInfo),
                onBlur: (event) => inputBlurHandler(event),
            },
            label: 'Name',
            error: addEmployeeInfo.nameError
        },
        department: {
            config: {
                type: 'text',
                value: addEmployeeInfo['department'],
                name: 'department',
                placeholder: 'Department',
                onChange: (event) => EmployeeFormHandler(event, 'department', addEmployeeInfo, setAddEmployeeInfo),
                onBlur: (event) => inputBlurHandler(event),
            },
            label: 'Department',
            error: addEmployeeInfo.departmentError

        },
        code: {
            config: {
                type: 'text',
                value: addEmployeeInfo['code'],
                name: 'code',
                placeholder: 'Code',
                onChange: (event) => EmployeeFormHandler(event, 'code', addEmployeeInfo, setAddEmployeeInfo),
                onBlur: (event) => inputBlurHandler(event),
            },
            label: 'Code',
            error: addEmployeeInfo.codeError
        }
    }
    const inputPropertiesEdit = {
        name: {
            config: {
                type: 'text',
                value: editEmployeeInfo['name'],
                name: 'name',
                placeholder: 'Name',
                onChange: (event) => EmployeeFormHandler(event, 'name', editEmployeeInfo, setEditEmployeeInfo),
                onBlur: (event) => inputBlurHandler(event),
            },
            label: 'Name',
            error: editEmployeeInfo.nameError
        },
        department: {
            config: {
                type: 'text',
                value: editEmployeeInfo['department'],
                name: 'department',
                placeholder: 'Department',
                onChange: (event) => EmployeeFormHandler(event, 'department', editEmployeeInfo, setEditEmployeeInfo),
                onBlur: (event) => inputBlurHandler(event),
            },
            label: 'Department',
            error: editEmployeeInfo.departmentError

        },
        code: {
            config: {
                type: 'text',
                value: editEmployeeInfo['code'],
                name: 'code',
                placeholder: 'Code',
                onChange: (event) => EmployeeFormHandler(event, 'code', editEmployeeInfo, setEditEmployeeInfo),
                onBlur: (event) => inputBlurHandler(event),
            },
            label: 'Code',
            error: editEmployeeInfo.codeError
        }
    }
    const EmployeeFormHandler = (event, name, getter, setter) => {
        let temp = getter;
        temp[name] = event.target.value
        setter({ ...temp })
    }
    const inputBlurHandler = () => {

    }
    useEffect(() => {
        //get employee list
        getList()
    }, [])

    const getList = async () => {
        let res = await GetEmployeeList()
        if (res.data && res.data.success) {
            // console.log("get employee list response:", res);
            setList(res.data.employeeList);
        }
        else {
            console.log("get  employee list error", res);
        }
        // axios.get('http://localhost:5100/employee/list')
        //     .then(response => {
        //         // console.log("get employee list response:", response.data);
        //         setList(response.data.employeeList);
        //     })
        //     .catch(error => {
        //         console.log("get  employee list error", error);
        //     })
    }

    const employeeButtonHandler = (type, employeeInfo) => {
        let temp = { ...modal }
        temp['open'] = true;
        temp['type'] = type
        if (employeeInfo && type === 'Edit') {
            let edit = editEmployeeInfo;
            edit['name'] = employeeInfo.name;
            edit['department'] = employeeInfo.department;
            edit['code'] = employeeInfo.code;
            edit['id'] = employeeInfo._id
            setEditEmployeeInfo({ ...edit })
        }
        if (employeeInfo && type === 'Delete') {
            let edit = deleteEmployeeInfo;
            edit['name'] = employeeInfo.name;
            edit['id'] = employeeInfo._id;
            setDeleteEmployeeInfo({ ...edit })
        }
        setModal({ ...temp })
    }

    const addEmployeeFunction = async () => {
        if (checkRequired()) {
            let payload = {
                name: addEmployeeInfo.name,
                code: addEmployeeInfo.code,
                department: addEmployeeInfo.department
            }
            console.log(payload);
            let res = await AddEmployee(payload)
            if (res.data && res.data.success) {
                console.log(res);
                getList();
                closeModal()
            }
            else {
                console.log("error", res);

            }
            // axios.post('http://localhost:5100/employee/addEmployee', payload)
            //     .then(response => {
            //         console.log(response);
            //         getList();
            //         closeModal()

            //     })
            //     .catch(error => {
            //         console.log("error", error);
            //     })
        }
    }
    const deleteEmployee = async () => {
        if (deleteEmployeeInfo.id) {
            let payload = {
                empId: deleteEmployeeInfo.id
            }
            console.log(payload);
            let res = await DeleteEmployee(payload)
            if (res.data && res.data.success) {
                console.log(res);
                getList();
                closeModal()
            }
            else {
                console.log("error", res);

            }

            // axios.post('http://localhost:5100/employee/deleteEmployee', payload)
            //     .then(response => {
            //         getList();
            //         closeModal()

            //     })
            //     .catch(error => {
            //         console.log("error", error);
            //     })
        }
    }
    const editEmployee = async () => {
        if (editEmployeeInfo.id) {
            let payload = {
                empId: editEmployeeInfo.id,
                name: editEmployeeInfo.name,
                code: editEmployeeInfo.code,
                department: editEmployeeInfo.department
            }
            console.log(payload);
            let res = await DeleteEmployee(payload)
            if (res.data && res.data.success) {
                console.log(res);
                getList();
                closeModal()
            }
            else {
                console.log("error", res);

            }
            // axios.post('http://localhost:5100/employee/editEmployee', payload)
            //     .then(response => {
            //         getList();
            //         closeModal()

            //     })
            //     .catch(error => {
            //         console.log("error", error);
            //     })
        }
    }
    const closeModal = () => {
        let temp = { ...modal }
        temp['open'] = false;
        temp['type'] = ''
        setModal({ ...temp });
        setAddEmployeeInfo(defaultInfo)
        setEditEmployeeInfo(defaultInfo)
        setDeleteEmployeeInfo(deleteDefaultVal)
    }
    const checkRequired = () => {
        let info = addEmployeeInfo;
        let flag = true
        for (const item in inputProperties) {
            console.log(info, info[item]);
            if (info[item]) {
                delete info[item + 'Error']
            }
            else {
                console.log("item", item);
                info[item + 'Error'] = 'Reqiured!'
                flag = false
            }
        }
        // Object.keys(inputProperties).forEach((item, index) => {
        //     console.log(item,index);
        //     if (info[item.label]) {
        //         delete info[item.label + 'Error']
        //     }
        //     else {      
        //         info[item.label + 'Error'] = 'Reqiured!'
        //         flag = false
        //     }
        // })
        console.log(info, flag);
        setAddEmployeeInfo({ ...info })
        return flag
    }
    const modalContent = {
        "Add": <div className={styles.modalContent} >
            {
                Object.keys(inputProperties).map((item, index) => {
                    return <div className={styles.inputWrapper} key={index}>
                        <Input inputProperties={inputProperties[item]}  />
                    </div>
                })
            }
            <div className={styles.row}>
                < div className={styles.smallSubmit} >
                    <Button styleType='light' onClick={closeModal}>Cancel</Button>
                </div >
                < div className={styles.smallSubmit} >
                    <Button onClick={addEmployeeFunction}>Add</Button>
                </div >
            </div>
        </div >,
        "Edit": <div className={styles.modalContent} >
            {
                Object.keys(inputPropertiesEdit).map((item, index) => {
                    return <div className={styles.inputWrapper}>
                        <Input inputProperties={inputPropertiesEdit[item]} key={index} />
                    </div>
                })
            }
            <div className={styles.row}>
                < div className={styles.smallSubmit} >
                    <Button styleType='light' onClick={closeModal}>Cancel</Button>
                </div >
                < div className={styles.smallSubmit} >
                    <Button onClick={() => editEmployee()}>Edit</Button>
                </div >
            </div>
        </div >,
        'Delete':
            <div className={styles.modalContent} >
                <p className={styles.deleteText}>delete {deleteEmployeeInfo.name} ?</p>
                <div className={styles.row}>
                    < div className={styles.smallSubmit} >
                        <Button styleType='light' onClick={closeModal}>Cancel</Button>
                    </div >
                    < div className={styles.smallSubmit} >
                        <Button onClick={() => deleteEmployee()}>Delete</Button>
                    </div >

                </div>
            </div >,
    }
    return (
        <>
            <Modal
                value={modal.open}
                parentClose={closeModal}
                title={modal.type === 'Add' ? 'Add Employee' : (modal.type === 'Edit' ? 'Edit Employee' : 'Delete Employee')} >
                {modalContent[modal.type]}
            </Modal>
            <Layout />
            <Container >
                <h2>Employee List</h2>
                <div className={styles.row}>
                    <div className={styles.addButtonwrapper}>
                        <Button onClick={() => employeeButtonHandler('Add')} >Add Employee</Button>
                    </div>
                </div>
                <div className={styles.titleCard} >
                    <div className={styles.cardInfo}>Name</div>
                    <div className={styles.cardInfo}>Code</div>
                    <div className={styles.cardInfo}>Department</div>
                </div>
                <div className={styles.contentWrapper}>
                    {list.map((item, index) => {
                        return <div className={styles.card} key={index}>
                            <div className={styles.cardInfo}>{item.name}</div>
                            <div className={styles.cardInfo}>{item.code}</div>
                            <div className={styles.cardInfo}>{item.department}</div>
                            <div className={styles.editEmployeeButtton} onClick={() => employeeButtonHandler('Edit', item)}>edit</div>
                            <div className={styles.deleteEmployeeButtton} onClick={() => employeeButtonHandler('Delete', item)}>delete</div>
                        </div>
                    })}

                </div>

            </Container>
        </>
    )
}

export default EmployeeList;
import Cookies from 'js-cookie';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './Container.module.scss';

const Container = ({ children, error }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const logoutHandler = () => {
        Cookies.remove("user-token");
        navigate('/login');
    }
    const renderNavigateButton = () => {
        const path = location.pathname
        if (path.includes('/orders')) {
            return <div className={styles.logout} onClick={() => navigate(-1)}>Change Employee<i></i></div>
        }
        else if (path.endsWith('departments') || path.endsWith('departments/')) {
          return  <div className={styles.logout} onClick={() => logoutHandler()}>Logout<i></i></div>
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                {children}
                {/* <div className={styles.logout} onClick={() => logoutHandler()}>Logout<i></i></div> */}
                {renderNavigateButton()}
            </div>
            <div className={styles.errorMessage}>
                <div className={error ? styles.showErrorMessage : styles.hideErrorMessage}>
                    <p>{error}</p>
                </div>
            </div>
        </div>
    )
}

export default Container;
import axios from "./helpers/axios";

//login register*********************************
export const LoginReq = (params) => {
    return axios.post('/admin/login', params).then(res => {
        console.log(res);
        return res
    }).catch(error => {
        console.log(error.response);
        return error
    })
}

export const SendCode = (params) => {
    return axios.post('/admin/send_code', params).then(res => {
        console.log(res);
        return res
    }).catch(error => {
        console.log(error.response);
        return error
    })
}

export const ReSendCode = (params) => {
    return axios.post('/user/send_code', params).then(res => {
        console.log(res);
        return res
    }).catch(error => {
        console.log(error.response);
        return error
    })
}

export const GetDepartments = () => {
    return axios.get('/employee/allDepartment').then(res => {
        console.log(res);
        return res
    }).catch(error => {
        console.log(error.response);
        return error
    })
}

export const GetDepartmentEmployees = (params) => {
    return axios.get('/employee/emp_by_department', params).then(res => {
        console.log(res);
        return res
    }).catch(error => {
        console.log(error.response);
        return error
    })
}

export const GetEmployeeList = (params) => {
    return axios.get('/employee/list', params).then(res => {
        console.log(res);
        return res
    }).catch(error => {
        console.log(error.response);
        return error
    })
}

export const AddEmployee = (params) => {
    return axios.post('/employee/addEmployee', params).then(res => {
        console.log(res);
        return res
    }).catch(error => {
        console.log(error.response);
        return error
    })
}

export const DeleteEmployee = (params) => {
    return axios.post('/employee/deleteEmployee', params).then(res => {
        console.log(res);
        return res
    }).catch(error => {
        console.log(error.response);
        return error
    })
}

export const EditEmployee = (params) => {
    return axios.post('/employee/editEmployee', params).then(res => {
        console.log(res);
        return res
    }).catch(error => {
        console.log(error.response);
        return error
    })
}

export const GetOrderTasks = (req) => {
    return axios.get(`/tasks/get_ptasks?department=${req}`).then(res => {
        console.log(res);
        return res
    }).catch(error => {
        console.log(error.response);
        return error
    })
}
// export const GetOrderTasks = (params) => {
//     // return axios.get(`/tasks/get_ptasks?department=${req}`).then(res => {
//     return axios.get(`/tasks/get_ptasks`, params).then(res => {
//         console.log(res);
//         return res
//     }).catch(error => {
//         console.log(error.response);
//         return error
//     })
// }
export const DoneTasks = (params) => {
    return axios.post('/tasks/done_tasks', params).then(res => {
        console.log(res);
        return res
    }).catch(error => {
        console.log(error.response);
        return error
    })
}
export const  UpdateDatabase = () =>{
    // https://staging.tekupservice.com/api/tasks/update_db`
    return  axios.get(`/tasks/update_db`).then(res=>{console.log(res); return res}).catch(err=>{console.log(err); return err})
}
export const  UpdatePipelines= () =>{
    return  axios.get(`/pipelines/update_pipelines`).then(res=>{console.log(res); return res}).catch(err=>{console.log(err); return err})
}
// export const UserLogin = (params) => {
//     return axios.post('/v1/user/login', params, { timeout: 30000 }).then(res => {
//         return res
//     }).catch(error => {
//         return error.response
//     })
// }

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './Login.module.scss';
import background from '../../assets/images/blue-background-geometrical-shape.jpg';
import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import CodeInput from '../../components/UI/CodeInput/CodeInput';
import Cookies from 'js-cookie';
import { LoginReq, ReSendCode, SendCode } from '../../ApiCalls'
import { ErrorServerWrapper } from '../../helpers/ErrorServerWrapper';

const Login = () => {
    const initialCodeState = {
        value: ['', '', '', '', ''],
        focus: 0
    }
    const [phone, setPhone] = useState('');
    const [code, setCode] = useState(initialCodeState)
    const [errors, setErrors] = useState({});
    const [activeCodeInput, setActiveCodeInput] = useState(false);
    const [resendCodeActive, setResendActive] = useState(false);
    const [timer, setTimer] = useState(0);
    const navigate = useNavigate();

    const phoneChangeHandler = (event) => {
        let temp = { ...phone };
        temp = event.target.value;
        setPhone(temp);
    }

    const codeChangeHandler = (event, index) => {
        let temp = { ...code };
        let lastIndex = code.value.length - 1;
        temp.value[index] = event.target.value;
        if (code.focus < lastIndex && event.target.value) {
            temp.focus = index + 1;
        }
        if (event.target.value.length > 1) {
            let copiedCode = event.target.value.split('');
            for (let i = 0; i < code.value.length; i++) {
                temp.value[i] = copiedCode[i];
                if (copiedCode.length > code.value.length) {
                    temp.focus = i;
                } else {
                    temp.focus = copiedCode.length;
                }
            }
        }
        if (code.focus === code.value.length - 1) {
            event.target.maxLength = 1;
        }

        setCode(temp);
        setErrors({ server: null })
    }

    const backSpaceHandler = (event) => {
        let temp = { ...code }
        switch (event.keyCode) {
            case 8:
            case 46:
                if (code.focus === code.value.length - 1) {
                    temp.focus--;
                    temp.value[code.value.length - 1] = ''
                } else if (code.focus > 0) {
                    temp.focus--;
                }
                break;
            case 37:
                temp.focus--;
                break;
            case 39:
                temp.focus++;
                break;
            default:
                break;

        }
        setCode(temp);
    }

    const inputBlurHandler = (event) => {
        console.log("hiiiiiiiiiiiiiiiiiiiii");
        let temp = { ...errors };
        const regex = new RegExp('^\\(?([0-9]{3})\\)?[-.\\s]?([0-9]{3})[-.\\s]?([0-9]{4})$');

        if (event.target.value === '') {
            temp.phone = 'Please enter your phone number.';
        }
        else if (!regex.test(event.target.value)) {
            temp.phone = 'Invalid phone number!';
        }
        else {
            delete temp[event.target.name];
        }
        setErrors(temp);
    }

    const validationHandler = () => {
        let temp = { ...errors };
        let phoneError = '', codeError = '';
        const regex = new RegExp('^\\(?([0-9]{3})\\)?[-.\\s]?([0-9]{3})[-.\\s]?([0-9]{4})$');

        if (phone === '') {
            phoneError = 'Please enter your phone number.';
        // }
        // else if (!regex.test(phone)) {
        //     phoneError = 'Invalid phone number!';
        } else {
            phoneError = '';
        }

        if (activeCodeInput) {
            if (code.value.join('') === '') {
                codeError = 'Please enter the verification code we sent to your phone number.'
            } else if (code.value.includes('')) {
                codeError = 'Invalid verification code!'
            } else {
                codeError = '';
            }
        }

        if (phoneError !== '' || codeError !== '') {
            temp.phone = phoneError;
            temp.code = codeError;
            setErrors(temp);
            return false;
        } else return true;

    }

    const getCodeHandler = async (event) => {
        event.preventDefault();
        const isValid = validationHandler();
        if (isValid) {
            let res = await ErrorServerWrapper(SendCode, { phone })
            // let res = await SendCode({ phone })
            console.log('send code res', res);
            if (res.data && res.data.success) {
                console.log("get code response:", res);
                setActiveCodeInput(true);
                setTimer(120);
                countDown();
                setErrors({});
            }
            else {
                console.log("byeee");
                console.log("get code error:", res.response);
                setErrors({ server: res.response.data ? res.response.data.error : 'Something went wrong!' })
            }
            // axios.post('http://localhost:5100/admin/send_code', { phone })
            //     .then(response => {
            //         console.log("get code response:", response);
            //         setActiveCodeInput(true);
            //         setTimer(120);
            //         countDown();
            //         setErrors({});
            //     })
            //     .catch(error => {
            //         console.log("byeee");
            //         console.log("get code error:", error.response);
            //         setErrors({ server: error.response.data ? error.response.data.error : 'Something went wrong!' })
            //     })
        } else {
            console.log("Something went wrong!");
        }

    }

    const submitHandler = async (event) => {
        event.preventDefault();
        const isValid = validationHandler();
        if (isValid) {
            const data = {
                phone: phone,
                code: code.value.join('')
            }
            let res = await LoginReq(data)
            if (res.data && res.data.success) {
                console.log("login response:", res);
                if (window.location.pathname.includes('/admin')) {
                    window.sessionStorage.setItem("admin-token", res.data.token);
                    // Cookies.set('admin-token', response.data.token)
                    navigate('/admin/employeelist')
                }
                else {
                    Cookies.set('user-token', res.data.token, { expires: 90 })
                    navigate('/departments');
                }
                setErrors({});
            }
            else {
                console.log("login error:", res.response);
                setErrors({ server: res.response.data.error })
            }
            // axios.post('http://localhost:5100/admin/login', data)
            //     .then(response => {
            //         console.log("login response:", response);
            //         if (window.location.pathname.includes('/admin')) {
            //             window.sessionStorage.setItem("admin-token", response.data.token);
            //             // Cookies.set('admin-token', response.data.token)
            //             navigate('/admin/employeelist')
            //         }
            //         else {
            //             Cookies.set('user-token', response.data.token)
            //             navigate('/departments');
            //         }
            //         setErrors({});
            //     })
            //     .catch(error => {
            //         console.log("login error:", error.response);
            //         setErrors({ server: error.response.data.error })
            //     })

        } else {
            console.log("invalid");
        }
    }


    const timerHandler = (timerInput) => {
        let minutes = '';
        let seconds = '';
        if (Math.floor(timerInput / 60) < 10) {
            minutes = ('0' + Math.floor(timerInput / 60))
        } else minutes = Math.floor(timerInput / 60);

        if (timerInput % 60 < 10) {
            seconds = ('0' + (timerInput % 60))
        } else seconds = (timerInput % 60);

        return '(' + minutes + ':' + seconds + ')';
    }

    const countDown = () => {
        setResendActive(false);
        setTimeout(() => {
            let temp = timer;
            temp--;
            setTimer(temp);
        }, 1000);
    }

    useEffect(() => {
        if (window.location.pathname.includes('/admin')) {
            // const token = Cookies.get('admin-token');
            const token = window.sessionStorage.getItem('admin-token');
            if (token) {
                navigate('/admin/employeelist')
            }
        }
        else {
            const token = Cookies.get('user-token');
            if (token) {
                navigate('/departments')
            }
        }
        if (activeCodeInput) {
            if (timer > 0) {
                countDown()
            } else {
                setResendActive(true);
            }
        }
    }, [timer])

    const resendCodeHandler = async () => {
        let res = await ReSendCode({ phone })
        if (res.data && res.data.success) {
            console.log("get code response:", res);
            setActiveCodeInput(true);
            setTimer(120);
            countDown();
            setErrors({})
        }
        else {
            console.log("get code error:", res.response);
            setErrors({ server: res.response.data.error })
        }
        // axios.post('http://localhost:5100/user/send_code', { phone })
        //     .then(response => {
        //         console.log("get code response:", response);
        //         setActiveCodeInput(true);
        //         setTimer(120);
        //         countDown();
        //         setErrors({})
        //     })
        //     .catch(error => {
        //         console.log("get code error:", error.response);
        //         setErrors({ server: error.response.data.error })
        //     })

    }

    const editPhoneHandler = () => {
        setActiveCodeInput(false);
        setCode(initialCodeState);
    }

    const inputProperties = {
        phone: {
            config: {
                type: 'text',
                value: phone,
                name: 'phone',
                placeholder: 'Phone Number',
                onChange: (event) => phoneChangeHandler(event),
                onBlur: (event) => inputBlurHandler(event),
            },
            error: errors.phone
        },
        code: {
            config: {
                //maxLength: '1',
                onKeyDown: (event) => backSpaceHandler(event),
                disabled: (!activeCodeInput)
            },
            code: code,
            error: errors.code,
            codeChangeHandler: codeChangeHandler,
        }
    }

    return (
        <div className={styles.login}>

            <div className={styles.loginSection}>
                <form autoComplete='off'>
                    <h2>Login</h2>

                    <Input inputProperties={inputProperties.phone} disabled={activeCodeInput} />
                    <div style={{ height: '40px', width: '100%' }}>
                        {!activeCodeInput ? <Button type='submit' onClick={(event) => getCodeHandler(event)}>
                            Get verification code
                        </Button>
                            : <p className={styles.editPhone} onClick={editPhoneHandler} >
                                Edit phone number
                            </p>
                        }
                    </div>

                    <div data-testid='codeInputs' className={styles.codeInputs} style={!activeCodeInput ? {
                        pointerEvents: 'none',
                        opacity: '0.4'
                    } : {}}>
                        <p className={styles.codeLabel}>Please enter your verification code:</p>
                        <CodeInput inputProperties={inputProperties.code} clearCode={() => setCode(initialCodeState)} />

                        <p className={styles.resend + ' ' +
                            (resendCodeActive ? styles.resendActiveButton : styles.resendInactiveButton)}
                            onClick={resendCodeActive ?
                                () => resendCodeHandler()
                                : null} >
                            Resend code {!resendCodeActive && timerHandler(timer)}
                        </p>
                        <Button type='submit' onClick={(event) => submitHandler(event)} >Verify</Button>
                    </div>
                </form>
                <div className={styles.errorMessage + ' ' +
                    (errors.server ? styles.showErrorMessage : styles.hideErrorMessage)}>
                    <p>{errors.server}</p>
                </div>
            </div>
            <div className={styles.imageSection}>
                <img src={background} alt='background' />
            </div>

        </div>
    )
}

export default Login;
import React from 'react';
import styles from './Input.module.scss';

const Input = ({ inputProperties, disabled }) => {
    return (
        <div className={styles.inputContainer}>
            <label>{inputProperties?.label}
                <input {...inputProperties.config} disabled={disabled} />
                <p>{inputProperties.error}</p>
            </label>
        </div>
    )
}

export default Input;
import './App.css';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import Login from './pages/Login/Login';
import Departments from './pages/Departments/Departments';
import Cookies from 'js-cookie';
import Orders from './pages/Orders/Orders';
import Employee from './pages/Employee/Employee';
import ResultMessage from './pages/ResultMessage/ResultMessage';
import EmployeeList from './pages/EmployeeList/EmployeeList';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/'>
          <Route index element={<Navigate to="/login" />} />
          <Route path='login' element={<Login />} />
          <Route path='/admin/login' element={<Login />} />
          <Route element={<ProtectedRoutes />}>
            <Route path='departments' >
              <Route index element={<Departments />} />
              <Route path=':department' >
                <Route index element={<Employee />} />
                <Route path='orders' >
                  <Route index element={<Orders />}/>
                  <Route path=':result' element={<ResultMessage/>}/>
                </Route>
              </Route>
            </Route>
          </Route>
          <Route element={<AdminProtectedRoutes />}>
            {/* <Route path='admin/employees' element={<Employee/>}/> */}
            <Route path='admin/employeelist' element={<EmployeeList/>}/>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export const useAuth = () => {
  const token = Cookies.get('user-token');

  if (token) {
    return true;
  } else return false;

}
export const useAuthAdmin = () => {
  // const token = Cookies.get('admin-token');
  const token =  window.sessionStorage.getItem('admin-token');

  if (token) {
    return true;
  } else return false;

}

const ProtectedRoutes = () => {
  const isAuth = useAuth();
  return isAuth ? <Outlet /> : <Navigate to='/login' />
}
const AdminProtectedRoutes = () => {
  const isAuth = useAuthAdmin();
  return isAuth ? <Outlet /> : <Navigate to='/admin/login' />
}

export default App;

import axios from "axios";
import Cookies from "js-cookie"

const instance = axios.create({
    baseURL: "https://staging.tekupservice.com/api/",
    //baseURL: "http://localhost:5200/",

});

export default instance;


